import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import { BiPhoneCall } from 'react-icons/bi'
import { BsCalendar4Range, BsClockHistory } from 'react-icons/bs'
import Navmenu from './NavMenu'
// import { Link } from 'react-router-dom'
import { FaMapMarkerAlt } from 'react-icons/fa'




const HeaderTwo = ({ btnName }) => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute z-[10] top-0 w-full'>

            <div className="md:w-100 md:flex hidden justify-around items-center bg2">
                <div className='w-[35%] -ml-[22%] h-full bg-footer'>
                    <ul className="flex justify-end space-x-4 my-5 pb-2 md:pb-0">
                        <li className="text-white md:text-[17px] text-[13px] md:block mr-28 ">
                            <span className="pr-3 flex items-center">
                                <FaMapMarkerAlt className="8px text-red-600 mr-2" />
                                {rpdata?.dbPrincipal?.location[0]?.address}
                            </span>
                        </li>

                    </ul>
                </div>

                <div>
                    <ul className="flex justify-center space-x-7 my-2">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                                <li key={index} className="text-white bg-2 p-3 w-[40px] h-[40px] flex items-center justify-center rounded-full ">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                        <i
                                            className={`fab fa-${item.icon}`}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>


            <div className='flex bg-white pb-4 '>
                <div className='md:flex w-[65%] lg:w-[72%] mx-auto justify-center items-center'>
                    <div className='w-full  md:w-[40%] lg:w-[21%]'>
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt='logo'
                            loading='lazy'
                            className='w-[100%] mx-auto my-2 md:ml-5'
                        />
                    </div>
                    <div className='flex space-x-3 w-full mx-auto justify-center'>
                        <div className='hidden w-[40%] text-black lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BiPhoneCall fontSize={45} className='border-black border-[3px] p-2' />
                            </div>
                            <div>
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                >
                                    <h5 className='text-[21px]'>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </h5>
                                    <p className='-mb-3'>Phone Number</p>

                                </a>
                            </div>
                        </div>
                        <div className='hidden w-[30%] text-black lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-5 '>
                                <BsCalendar4Range fontSize={45} className='border-black border-[3px] p-2' />
                            </div>
                            <div>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                </h5>

                                <p className='-mb-3'>Workdays</p>
                            </div>
                        </div>
                        <div className='hidden w-[40%] text-black  lg:flex mx-auto justify-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BsClockHistory fontSize={45} className='border-black border-[3px] p-2'  />
                            </div>
                            <div>
                                <h5 className='text-[21px]'>
                                    {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                </h5>
                                <p className='-mb-3'>Work Hours</p>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='bg-2 px-3 py-4 md:w-[80%] mx-auto md:-mt-6 md-0 '>
                <div className='flex justify-between md:justify-center items-center'>
                    <nav>
                        <Navmenu />
                    </nav>
                    <div className='block md:hidden'>
                        <ButtonContent />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderTwo